<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="90px"
      :model="fromData"
      :rules="rules"
      ref="fromData"
    >
      <el-form-item label="分类名称:" prop="name">
        <el-input v-model.trim="fromData.name"></el-input>
      </el-form-item>
      <el-form-item label="简码:" prop="brief_code">
        <el-input v-model.trim="fromData.brief_code"></el-input>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
export default {
  name: "EditType",
  props: {
    type: String,
    show: Boolean,
    item: Object,
  },
  data() {
    return {
      hide: false,
      title: "新增分类",
      labelPosition: "right",
      fromData: {
        id: "",
        name: "",
        brief_code: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        brief_code: [
          { required: true, message: "简码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增材质分类";
        this.fromData = {
          id: "",
          name: "",
          brief_code: "",
          sort: 1,
        };
        this.imageSrc = "";
      } else {
        this.title = "编辑材质分类";
        this.fromData.id = this.item.id;
        this.fromData.name = this.item.name;
        this.fromData.brief_code = this.item.brief_code;
        this.fromData.sort = this.item.sort;
      }
    },
  },
  methods: {
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      let fromData = new FormData();
      fromData.append("name", this.fromData.name);
      fromData.append("brief_code", this.fromData.brief_code);
      if (this.type === "add") {
        this.handleAddComfirm(fromData);
        return;
      } else {
        fromData.append("id", this.fromData.id);
        this.handleEditComfirm(fromData);
      }
    },
    //新增提交
    async handleAddComfirm(fromData) {
      const res = await this.$store.dispatch("addStuffType", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(fromData) {
      const res = await this.$store.dispatch("editType", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>