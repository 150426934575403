<template>
  <table-container :list="list">
    <el-table-column type="index" label="序号"> </el-table-column>
    <el-table-column prop="name" label="名称"> </el-table-column>
    <el-table-column prop="brief_code" label="简码"> </el-table-column>
    <el-table-column prop="created_at" label="创建日期"> </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <!-- <el-button type="primary" @click="handleEdit(scope.row)"
          >编辑</el-button
        > -->
        <delete-btn :item="scope.row" @del="handleDelete"></delete-btn>
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    handleEdit(item) {
      this.$emit("edit", item);
    },
    async handleDelete(item) {
      const res = await this.$store.dispatch("delPhoneStuffCategory", {
        category_id: item.id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>